import React from "react"
import { graphql } from 'gatsby'

export default ({ data }) => {
  return (
    <div>
      <h1>Site Files</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Path</th>
            <th>Size</th>
            <th>Extension</th>
            <th>Date Created</th>
          </tr>
        </thead>
        <tbody>
          {data.allFile.edges.map(({node}, index) => 
            <tr key={index}>
              <td>{node.name}</td>
              <td>{node.relativePath}</td>
              <td>{node.prettySize}</td>
              <td>{node.extension}</td>
              <td>{node.birthTime}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export const query = graphql`
  query MyFilesQuery {
    allFile {
      edges {
        node {
          name
          relativePath
          prettySize
          extension
          birthTime(fromNow: true)
        }
      }
    }
  }
`